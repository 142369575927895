<template>
  <div>
    <leanpay-datatable-component
      title="Domain"
      store-module="domains"
      store-data-endpoint="getDomains"
      store-getter-endpoint="domainsList"
      pagination-state-key="domainsList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
    />
    <!--    <leanpay-datatable-component-->
    <!--      title="Redbird Domain"-->
    <!--      store-module="domains"-->
    <!--      store-data-endpoint="getDomainsRedbird"-->
    <!--      store-getter-endpoint="domainsList"-->
    <!--      pagination-state-key="domainsList"-->
    <!--      :features="loadedConfig.datatableFeatures"-->
    <!--      :load-system-constant="true"-->
    <!--      default-search-field="id"-->
    <!--      default-sort-field="created_at"-->
    <!--      :search-options-dropdown="loadedConfig.searchOptsDropdown"-->
    <!--      :conf="loadedConfig"-->
    <!--    />-->
  </div>
</template>

<script>
import Config from '@/views/administration/domains/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import leanpayDatatableComponent from '@/leanpay-components/LeanpayDatatableComponent.vue'

export default {
  components: { leanpayDatatableComponent },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Domains',
      dataGridEndpoint: [],
      module: 'domains',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      searchFieldOpts: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'invoice_status', text: 'Invoice Status' },
      ],
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Domains' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')

    // this.initDataGrid()
  },
  methods: {

  },
}
</script>
